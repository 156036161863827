/**
		Search Templates
	 */
.catalog-search {
	width: $mini-container-width;
	margin: 0 auto;
}

.catalog-search span {
	border-right: none !important;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;

	i {
		color: $gray-400;
	}
}

.catalog-search .input-group-text {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.catalog-search input {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	min-height: 40px;
	border-left: none;
	color: $gray-400;
}

/**
		Tags
	 */
.tags li {
	margin-right: 0.25rem !important;
}

.tags .tags-category {
	margin-bottom: 1rem;
}

/**
		Templates List
	 */
.templates {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	grid-template-rows: auto;
	grid-gap: 1.5rem;
}

.templates-item {
	background-color: white;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
	cursor: pointer;
}

.templates-top {
	padding: 0.315rem 0 0.44rem 0.5rem;
	margin-bottom: 0.5rem;
	border-bottom: 1px solid #f1f1f1;
	display: flex;

	.dot {
		width: 4px;
		height: 4px;
		background-color: $gray-200;
		display: inline-block;
		border-radius: 50%;
		margin-right: 0.25rem;
	}
}

.templates-thumbnail {
	padding: 0 1rem;
	margin-bottom: 0.94rem;
	max-height: 108px;
	width: 100%;
}

.templates-name {
	@extend %text-sm;
	text-align: center;
	padding: 0 10px;
}

.templates-header {
	margin-top: auto;
}

.templates-preview {
	.modal-dialog {
		max-width: 100%;
		width: 100%;
		margin: 0;
	}

	.modal-content {
		background-color: transparent;
		box-shadow: none;
		border: none;
		position: relative;
	}

	.modal-body {
		border-bottom: none;
		padding: 0;
		display: flex;
		justify-content: center;
	}

	.modal-header {
		background-color: #fff;
		border-radius: 10px;
		width: 700px;
		margin: 0 auto;
		position: absolute;
		bottom: 25px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 9999;

		a,
		button {
			@extend %text-menu;
			font-size: 16px;
			padding: 1rem 1.5rem;
			border-radius: 0.5rem;
		}
	}

	.templates-preview-iframe {
		width: 100vw;
		height: 100vh;
	}
}
