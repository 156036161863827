.table {
	color: $gray-600;
	th,
	a {
		@extend %text;
		border-top: none;
		border-bottom-width: 1px !important;
		color: inherit;
		text-decoration: none;

		.active {
			font-weight: 700;
		}
	}

	td {
		@extend %text-sm;

		a:first-of-type {
			padding-left: 0;
		}

		a,
		button {
			@extend %text-sm;
			color: $gray-600;
		}
		i {
			color: $gray;
		}
	}

	td.item-name {
		font-weight: bold;
		color: black;
	}

	th,
	td {
		vertical-align: baseline;
		border-bottom-color: $gray-200 !important;
		font-weight: 500;
	}
}

.wide-actions-cell tr > td:last-of-type {
	min-width: 370px;
}
