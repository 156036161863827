.navbar {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);

	a,
	button {
		@extend %text-menu;
	}

	li {
		a {
			color: black !important;
			&.btn:hover {
				color: white;
			}
		}

		.btn.btn-outline-success {
			color: $green !important;

			&.active {
				color: white !important;
			}

			&:hover {
				color: white !important;
			}
		}
	}
}

.navbar-brand {
	font-size: 18px !important;
	align-self: center;
}

.navbar-layout {
	display: grid;
	grid-template-columns: repeat(3, auto);
	width: 100%;
}

.navbar-center {
	align-self: center;
	justify-self: center;

	input {
		@extend %text-menu;
		font-size: 28px;
		text-align: center;
		border: none;
		max-width: 450px;

		&:hover {
			outline: 1px solid #ced4da;
			outline-offset: -1px;
		}

		&:disabled {
			background-color: transparent;
			color: black;
			cursor: no-drop;
		}
	}
}
