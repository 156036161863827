/**
		Tags
	 */
.tags {
	.tags-category-name {
		margin-right: 1rem;
		@extend %text;
	}

	.tags-items {
		margin: 0;
		padding: 0;

		li {
			display: inline-block;
			margin-bottom: 0.25rem;
		}

		a,
		.tags-item {
			padding: 0.25rem 1rem;
			background-color: white;
			color: black;
			border: 1px solid $gray-400;
			border-radius: 25px;
			@extend %text-sm;

			&.active,
			&:hover,
			&:active {
				background-color: $blue;
				border: 1px solid $blue;
				color: white;
				text-decoration: none;
			}
		}
	}
}

#selected-tags {
	li {
		display: inline-flex;
		justify-content: space-between;
		align-items: baseline;
		list-style-type: none;
		background-color: white;
		border: 1px solid #ddd;
		padding: 0.2rem 1rem;
		border-radius: 0.25rem;
		margin-right: 0.5rem;
		margin-bottom: 0.5rem;

		span {
			margin-right: 5px;
		}

		button {
			padding-right: 0 !important;
		}
	}
}
