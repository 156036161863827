.text-gray-600 {
	color: $gray-600;
}

.text-gray-600--hover,
.text-gray-600--hover:hover {
	color: $gray-600;
}

.bg-light {
	background-color: white !important;
}

/**
	Selectize
 */
.selectize-dropdown-content {
	.tags-input-option {
		padding-left: 1.25rem;
	}

	.tags-input-item {
		&.active {
			background: inherit !important;
		}
	}
}

.fs-5 {
	font-size: 1.25rem!important;
}

.fw-bold {
	font-weight: 700!important;
}