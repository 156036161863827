.mini-container{
	width: 425px;
	background: #FFFFFF;
	box-shadow: 0 4px 32px 0 #0000000D;
	border-radius: 8px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
