html,
body {
	height: 100%;
}

.editor {
	height: calc(100% - 60px);

	iframe {
		width: 100%;
		height: 100%;
	}
}
