.sharingModal {
	.modal-header,
	.modal-body,
	.modal-footer {
		padding: 1rem 1.5rem;
	}

	.modal-header {
		border-bottom-color: #f1f1f1;
	}

	.modal-footer {
		border-top-color: #f1f1f1;
		justify-content: flex-start;

		button {
			font-size: 0.75rem;
			font-weight: normal;
			margin: 0;
			padding: 0;

			i {
				font-size: 16px;
			}

			&.btn-link:hover {
				text-decoration: none;
			}
		}
	}

	form input,
	form button {
		font-size: 14px;
	}

	.sharing-row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;

		.icon {
			width: 2rem;
			height: 2rem;
			margin-right: 0.75rem;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 12px;
			color: white;

			i {
				font-size: 20px;
				color: $gray-700;
			}
		}

		.text {
			margin-right: auto;
			font-size: 0.88rem;
		}

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	.permissions {
		color: $gray-600;
		font-size: 0.75rem;
	}

	select {
		background-color: transparent;
		border: none;
		cursor: pointer;
		font-size: 12px;

		&:focus-visible {
			outline: none;
		}
	}

	.autocomplete-input-container {
		min-height: 40px;
		padding: 0;
		max-width: 512px;
		position: relative;
		box-sizing: border-box;
		background-color: #f1f3f4;
		color: #c4c4c4;
		border: 2px solid #f1f3f4;
		border-radius: 0.25rem;
		display: flex;
		flex-direction: row;
		align-items: center;

		&:focus {
			background-color: #fff;
			box-shadow: inset 0 0 0 2px #0079bf;
		}
	}

	.autocomplete-search-results {
		display: none;
		width: 100%;
		top: 120%;
		left: -1px;
		box-shadow: 0 8px 16px -4px rgb(9 30 66 / 25%), 0 0 0 1px rgb(9 30 66 / 8%);
		background-color: #fff;
		border-radius: 3px;
		box-sizing: border-box;
		position: absolute;
		z-index: 10;
		margin: 0;
		overflow-y: visible;

		.autocomplete-search-scroll {
			box-sizing: border-box;
			max-height: 200px;
			overflow-y: auto;
			padding: 0;
			margin: 0;
			width: 100%;
		}

		.popup-invitee {
			border-bottom: 1px solid #ddd;
			padding: 5px;
			width: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			cursor: pointer;
			transition: background-color 0.2s;

			&:hover {
				background-color: $gray-200;
			}

			&:last-of-type {
				border-bottom: none;
			}

			.icon {
				width: 2rem;
				height: 2rem;
				margin-right: 0.75rem;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				font-size: 12px;
				color: white;
			}

			.username {
				font-size: 12px;
				color: black;
			}

			.email {
				font-size: 11px;
				color: $gray-600;
			}
		}
	}

	.autocomplete-selected {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		overflow-y: auto;
		max-width: 210px;

		&:focus {
			outline: none;
			border: 2px solid $blue;
			box-shadow: none;
			color: $gray-600;
		}

		.popup-invitee {
			background-color: white;
			border: 1px solid #ddd;
			border-radius: 0.25rem;
			font-size: 12px;
			display: inline-flex;
			flex-direction: row;
			align-items: center;
			color: #666;
			padding: 2px 5px;
		}
	}

	.guest-email {
		font-size: 14px;
		height: 24px;
		margin: 3px 7px 3px 0;
		flex: 1;
		min-width: 80px;
		min-height: 0;
		background-color: transparent;
		border: none;

		&:focus {
			outline: none;
			border: none;
			box-shadow: none;
		}
	}

	form .collaborator-permissions {
		position: absolute;
		top: 10px;
		right: 10px;
	}

	.embed-code-footer {
		display: flex;
		flex-direction: row;
		justify-content: flex-end;

		button {
			font-size: 0.75rem;
			font-weight: normal;
		}
	}
}
