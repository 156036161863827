.toggle-password-visibility {
	position: absolute;
	z-index: 2;
	top: 33px;
	right: 5px;
	width: 34px;
	height: 34px;
	background-color: transparent;
	border: none;
	padding: 0;

	i {
		color: $gray-400;
	}
}
