.drag-and-drop-form {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	.drag-and-drop-input {
		display: none;
	}

	.drag-and-drop-label {
		width: 100%;
		border: 2px dashed #CCC;
		text-align: center;
		display: block;
		padding: 10rem 5rem;
		transition: all .2s;
		cursor: pointer;
		color: #666;
		font-size: 16px;
	}

	i {
		font-size: 2rem;
		margin-bottom: .75rem;
	}

	&.is-dragover .drag-and-drop-label,
	.drag-and-drop-label:hover {
		color: black;
		border-color: black;
	}
}
