/**
		Search Templates
	 */
.popups-search {
	width: $mini-container-width;
}

.popups-search span {
	border-top-left-radius: 0.5rem;
	border-bottom-left-radius: 0.5rem;
	background-color: #f1f3f4;
	border: none;

	i {
		color: $gray-600;
	}
}

.popups-search .input-group-text {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}

.popups-search input {
	border-top-right-radius: 0.5rem;
	border-bottom-right-radius: 0.5rem;
	min-height: 40px;
	border-left: none;
	color: $gray-600;
	background-color: #f1f3f4;
	border: none;

	&:focus {
		background-color: #f1f3f4;
		color: $gray-600;
	}
}
