/**
	Colors
 */
$green: #0ED58D;
$green-100: #F4FAF8;

$blue: #2A70D9;

$gray: #C4C4C4;
$gray-100: #F1F1F1;
$gray-200: #E0E0E0;
$gray-600: #606368;
$gray-700: #3C4043;

/**
	Fonts
 */
$primaryFontFamily: Inter, sans-serif;
$secondaryFontFamily: Roboto, sans-serif;

/**
	Measurements
 */
$mini-container-width: 425px;
