body {
	background-color: $green-100;
	font-family: $primaryFontFamily;
	color: black;
}

table th a {
	i {
		display: none !important;
	}
}
