%text {
	font-family: $secondaryFontFamily;
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
}

%text-sm {
	font-family: $primaryFontFamily;
	font-size: 0.875rem;
	font-style: normal;
	font-weight: 400;
}

%text-xs {
	font-family: $primaryFontFamily;
	font-size: 0.75rem;
	font-style: normal;
	font-weight: 400;
}

%text-menu {
	font-family: $primaryFontFamily;
	font-size: 1rem;
	font-style: normal;
	font-weight: 700;
}

%h3 {
	font-family: $primaryFontFamily;
	font-size: 1.75rem;
	font-style: normal;
	font-weight: 700;
	text-align: left;
}

h1 {
	font-family: $primaryFontFamily;
	font-size: 2.5rem;
	font-weight: 900;
	text-align: center;
	margin-bottom: 1rem;
}

h2 {
	font-family: $primaryFontFamily;
	font-size: 1.75rem;
	font-weight: 900;
	text-align: center;
	margin-bottom: 1rem;
}

h3 {
	@extend %h3;
}

h4 {
	font-family: $primaryFontFamily;
	font-size: 1.25rem;
	font-style: normal;
	font-weight: 700;
	text-align: left;
}

label,
p {
	font-family: $primaryFontFamily;
	font-size: 0.875rem;
	font-weight: 400;
	text-align: left;
	color: black;
}

a {
	text-decoration: none;
}

.x-small {
	font-size: 0.75rem;
}
